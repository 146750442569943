import React from "react";
import ProjectCard from "../components/ProjectCard";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { motion, useScroll } from "framer-motion";

import logoVolocopter from "../assets/svg/projects/Volocopter.svg";
import logoABB from "../assets/svg/projects/ABB.svg";
import logoBrailly from "../assets/svg/projects/Brailly.svg";
import logoSkateboard from "../assets/svg/projects/Skateboard.svg";
import logoButterfly from "../assets/svg/projects/Butterfly.svg";
import logoPatchMarket from "../assets/svg/projects/PatchMarket.svg";
import evtolImage from "../assets/png/Volocopter.png";
import BrallyImage from "../assets/png/Brailly.png";
import ButterflyImage from "../assets/png/Butterfly.png";
import SkateboardImage from "../assets/png/Skateboard.png";
import ABBImage from "../assets/png/ABB.png";
import PatchMarketImage from "../assets/png/PatchMarket.png";
import BackgroundBlur from "../components/BackgroundBlur";

interface ProjectPageProps {
  mode: "dark" | "light";
}

const ProjectPage: React.FC<ProjectPageProps> = ({ mode }) => {
  const { scrollYProgress } = useScroll();

  return (
    <>
      {/* Scroll Progress Bar */}
      <motion.div
        className="fixed top-0 left-0 h-full w-2 bg-orange-500 z-50"
        style={{ scaleY: scrollYProgress, transformOrigin: "top" }}
      />

      {/* Main Content */}
      <div className="relative min-h-screen creations-page">
        {/* Background Blur */}
          <BackgroundBlur />

        {/* Header */}
        <div className="relative ">
          <div className="container mx-auto py-32 flex items-start max-w-screen-lg">
            <Header mode={mode} text="SELECTED WORK" subtext="Highlighting my latest projects, crafted with passion" />
          </div>

          {/* Divider */}
          <div
            className={`w-full border-t ${
              mode === "dark" ? "border-[#eeeeee29]" : "border-[#dddddd]"
            }`}
          ></div>

          {/* Project Cards in Single Column     className="portfolio-page container mx-auto px-4 sm:px-6 md:px-8 py-12 mb-12 flex flex-col gap-4"
  */}
          <motion.div
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, ease: "easeInOut" }}
            className={`portfolio-page container justify-center mx-auto max-w-full px-4 sm:px-6 md:px-12 py-12 mb-12 flex flex-col gap-4 ${

              mode === "dark" ? "text-white" : "text-black"
            }`}
          >
            


          <ProjectCard
            title="Interface Design and Interaction Modeling for a new innovative propulsion system"
            description="Developed and prototpyed first solutions for human machine interface in collaboration with ABB."
            logoSrc={logoABB}
            imageSrc={ABBImage}
            darkMode={mode === "dark"}
            customImageStyle={{ borderRadius: "20px" }}
            link="/project/master-thesis-abb" // Internal route
            pdfLink="https://urn.fi/URN:NBN:fi:aalto-202412167812" // FIX THIS / ALSO THE COLOR
            external={false}
            mode={mode}
          />
          <ProjectCard
            title="brailly - Empowering Independence Through Engaging Braille Learning"
            description=" Brailly is a young startup offering an interactive self-learning device to learn Braille language in a fun and intuitive way."
            logoSrc={logoBrailly}
            imageSrc={BrallyImage}
            darkMode={mode === "dark"}
            customImageStyle={{ borderRadius: "12px" }}
            link="https://brailly.eu" // External link
            external={true} // Opens in new tab
            buttonText="Visit Brailly"
            mode={mode}
          />
          <ProjectCard
            title="Antenna Communication Simulation and Testing "
            description="Radio communication, EVTOL datalink communication analysis, Offload data research on the new Wifi 6E band, S/N ratios, multipath propagation."
            logoSrc={logoVolocopter}
            imageSrc={evtolImage}
            darkMode={mode === "dark"}
            customImageStyle={{ borderRadius: "15px" }}
            link="/project/bachelor-thesis-evtol" // Internal route
            external={false}
            mode={mode}
          />
          <ProjectCard
            title="PatchMarket: Sustainable Marketplace"
            description="Developed a marketplace app connecting local artisans and sustainable products, fostering community-driven commerce."
            logoSrc={logoPatchMarket}
            imageSrc={PatchMarketImage}
            darkMode={mode === "dark"}
            customImageStyle={{ borderRadius: "15px" }}
            link="https://patch-market.onrender.com/"
            external={true}
            note="First load may be slow due to free hosting limits."
            pdfLink="../assets/PDF/WWWDesignPhase.pdf" // Link to PDF
            buttonText="Try Out"
            mode={mode}
          />
          <ProjectCard
            title="Meditiation Exercise"
            description="The Breathing Soft butterfly is an Arduino-powered interactive installation. It features a papier-mâché butterfly that flaps its wings in sync with the user's breathing, monitored by a custom breathing sensor. This creative design blends electronics and mindfulness, encouraging individuals to focus on conscious breathing, promoting calm and relaxation."
            logoSrc={logoButterfly}
            imageSrc={ButterflyImage}
            darkMode={mode === "dark"}
            customImageStyle={{ borderRadius: "18px" }}
            pdfLink="../assets/PDF/BreathingSoft.pdf"
            videoLink="https://www.youtube.com/watch?v=6Vud2sTFe4g"
            mode={mode}
          />
          <ProjectCard
            title="ELectric Skateboard Design and Engineering"
            description="A creative project exploring propulsion drive, batteries and control engineering for high speed riding experiences."
            logoSrc={logoSkateboard}
            imageSrc={SkateboardImage}
            darkMode={mode === "dark"}
            customImageStyle={{ borderRadius: "10px" }}
            link="/project/skateboard-design" // Internal route
            mode={mode}
          />

            {/* Add remaining ProjectCards here */}
            </motion.div>
        </div>

        {/* Footer
         */}
        <Footer mode={mode} className="relative z-10 mt-12" />


      </div>
    </>
  );
};

export default ProjectPage;