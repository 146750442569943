import React, { useState, useEffect } from "react";
import "./PhotographyPage.css";
import imagesData from "./images.json";
import Footer from "../components/Footer";
import Header from "../components/Header";
import BackgroundBlur from "../components/BackgroundBlur";

interface PhotographyPageProps {
  mode: "dark" | "light";
  onFullscreenChange: (isFullscreen: boolean) => void;
}

const PhotographyPage: React.FC<PhotographyPageProps> = ({
  mode,
  onFullscreenChange,
}) => {
  const [images, setImages] = useState<any[]>([]);
  const [fullscreenIndex, setFullscreenIndex] = useState<number | null>(null);

  useEffect(() => {
    setImages(imagesData);
  }, []);

  // Disable body scrolling when modal is open
  useEffect(() => {
    if (fullscreenIndex !== null) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    return () => {
      document.body.style.overflow = ""; // Cleanup on unmount
    };
  }, [fullscreenIndex]);

  const openFullscreen = (index: number) => {
    setFullscreenIndex(index);
    onFullscreenChange(true);
  };

  const closeFullscreen = () => {
    setFullscreenIndex(null);
    onFullscreenChange(false);
  };

  const navigateFullscreen = (direction: "left" | "right") => {
    if (fullscreenIndex !== null) {
      const newIndex =
        direction === "left"
          ? (fullscreenIndex - 1 + images.length) % images.length
          : (fullscreenIndex + 1) % images.length;
      setFullscreenIndex(newIndex);
    }
  };

  return (
    <div className="relative min-h-screen overflow-hidden">
      {/* Background Blurs */}
      <BackgroundBlur />

      {/* Page Content */}
      <div className=" leading-[normal] container mx-auto py-32 flex items-start max-w-screen-lg z-100">
        <Header
          mode={mode}
          text="ANALOGUE MEMORIES"
          subtext="Every photo reflects my dedication to the entire process of film photography, from enlarging in the darkroom to meticulously scanning the final result"
        />
      </div>

      {/* Divider */}
      <div
        className={`w-screen border-t ${
          mode === "dark" ? "border-[#eeeeee29]" : "border-[#dddddd]"
        }`}
      ></div>

      {/* Image Gallery */}
      <div className="photography-gallery z-10 lg:px-28 md:px-18 sm:px-10 px-6">
        {images.map((image, index) => (
          <div
            key={image.id}
            className="gallery-item"
            onClick={() => openFullscreen(index)}
          >
            <img
              loading="lazy"
              src={`${image.prefix}.${image.suffix}`}
              alt={`Photography by Lars Oberhofer - ${
                image.prefix.split("/").pop()
              }`}
              className="gallery-image"
            />
          </div>
        ))}
      </div>

      {fullscreenIndex !== null && (
  <div
    className="fullscreen-modal open z-40 fixed inset-0 flex justify-center items-center bg-black bg-opacity-90 p-4"
    onClick={closeFullscreen} // This only triggers when clicking directly on the modal background
  >
    {/* Close Button */}
    <button
      className="absolute top-4 right-4 text-white z-50 text-3xl"
      onClick={(e) => {
        e.stopPropagation(); // Prevent modal from closing
        closeFullscreen();
      }}
    >
      &times;
    </button>

    {/* Left Clickable Area */}
    <div
      className="absolute top-4 left-4 bottom-4 w-1/2 cursor-pointer z-40"
      onClick={(e) => {
        e.stopPropagation(); // Prevent modal from closing
        navigateFullscreen("left");
      }}
    ></div>

    {/* Fullscreen Image */}
    <img
      src={`${images[fullscreenIndex].prefix}.${images[fullscreenIndex].suffix}`}
      alt="Fullscreen"
      className="max-w-full max-h-full z-40 p-2"
      onClick={(e) => e.stopPropagation()} // Prevent modal from closing
    />

    {/* Right Clickable Area */}
    <div
      className="absolute top-4 right-4 bottom-4 w-1/2 cursor-pointer z-40"
      onClick={(e) => {
        e.stopPropagation(); // Prevent modal from closing
        navigateFullscreen("right");
      }}
    ></div>
  </div>
)}



      {/* Footer */}
      <Footer mode={mode} className="relative z-10 mt-12" />
    </div>
  );
};

export default PhotographyPage;
