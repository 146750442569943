import PropTypes from "prop-types";
import React from "react";

interface Props {
  mode: "dark" | "light";
  className?: string; // Optional className for additional styling
  text: string; // Title text prop
  subtext: string; // Subtitle text prop
}

export const Header = ({ mode, className = "", text, subtext }: Props): JSX.Element => {
  const isLightMode = mode === "light";

  return (
    <div className={`flex px-4 flex-col items-start gap-2 ${className}`}>
      {/* Header title */}
      <div
        className={`font-sans font-bold text-left text-2xl sm:text-3xl md:text-4xl lg:text-5xl ${
          isLightMode ? "text-[#181818]" : "text-white"
        }`}
      >
        {text}
      </div>
  
      {/* Subheading text */}
      <p
        className={`font-mono font-normal  text-left text-sm sm:text-base md:text-lg lg:text-xl ${
          isLightMode ? "text-[#5f5f5f]" : "text-[#cfcfcf]"
        }`}
      >
        {subtext}
      </p>
    </div>
  );
  
};

Header.propTypes = {
  mode: PropTypes.oneOf(["dark", "light"]).isRequired,
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  subtext: PropTypes.string.isRequired,
};

export default Header;
