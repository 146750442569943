import React, { useState } from "react";
import Button from "./Button";
import PDFViewer from "./PDFviewer";

interface ProjectCardProps {
  title: string;
  description: string;
  logoSrc: string;
  imageSrc: string;
  darkMode: boolean;
  customImageStyle?: React.CSSProperties;
  link?: string;
  external?: boolean;
  note?: string;
  pdfLink?: string;
  videoLink?: string;
  buttonText?: string;
  mode: "dark" | "light";
}

const isExternalLink = (url?: string): boolean =>
  url ? !url.startsWith("/") && url.includes("http") : false;

const ProjectCard: React.FC<ProjectCardProps> = ({
  title,
  description,
  logoSrc,
  imageSrc,
  darkMode,
  customImageStyle,
  note,
  link,
  mode,
  pdfLink,
  videoLink,
  buttonText = "Learn More",
  external = link ? isExternalLink(link) : false,
}) => {
  const [showPDF, setShowPDF] = useState(false);
  const [showVideo, setShowVideo] = useState(false);

  const handleViewVideo = () => setShowVideo(true);

  return (
    <div
      className={`relative w-full max-w-[1024px] mx-auto rounded-2xl overflow-hidden font-mono ${
        darkMode
          ? "border border-[#414141] shadow-card-dark bg-[#131313]"
          : "border border-[#dddddd] shadow-card-light bg-[#f9f9f9]"
      }`}
    >
      {/* Mobile and Desktop Layout */}
      <div className="flex flex-col lg:flex-row">
        {/* Image Section */}
        <div className="w-full h-[200px] lg:w-[40%] lg:h-auto flex-shrink-0">
          <img
            src={imageSrc}
            alt={title}
            className="w-full h-full object-cover"
          />
        </div>

        {/* Content Section */}
        <div className="p-6 lg:p-8 flex flex-col justify-between">
          {/* Logo and Title */}
          <div className="flex flex-col gap-4">
            <div className="w-16 h-16 lg:w-20 lg:h-20 rounded-2xl overflow-hidden shadow-md">
              <img
                src={logoSrc}
                alt={`${title} logo`}
                className="w-full h-full object-contain"
              />
            </div>
            <h2
              className={`text-lg lg:text-2xl font-extrabold ${
                darkMode ? "text-white" : "text-[#181818]"
              }`}
            >
              {title}
            </h2>
            <p
              className={`text-sm lg:text-base ${
                darkMode ? "text-[#7f7f7f]" : "text-[#7f7f7f78]"
              }`}
            >
              {description}
            </p>

            {/* Note */}
            {note && (
              <p
                className={`text-xs lg:text-sm font-medium ${
                  darkMode ? "text-[#c4c4c4]" : "text-[#7f7f7f]"
                }`}
              >
                {note}
              </p>
            )}
          </div>

          {/* Buttons */}
          <div className="flex flex-wrap gap-4 mt-6">
            {videoLink && (
              <Button
                text="Watch Video"
                variant="primary"
                mode={darkMode ? "dark" : "light"}
                onClick={handleViewVideo}
              />
            )}
            {link && (
              <Button
                text={buttonText}
                variant="primary"
                mode={darkMode ? "dark" : "light"}
                iconRight={true}
                link={link}
              />
            )}
            {pdfLink && (
              <Button
                text="View PDF"
                variant="text"
                mode={darkMode ? "dark" : "light"}
                onClick={() =>
                  pdfLink === "https://urn.fi/URN:NBN:fi:aalto-202412167812"
                    ? window.open(pdfLink, "_blank")
                    : setShowPDF(true)
                }
              />
            )}
          </div>
        </div>
      </div>
{/* PDF Viewer */}


{showPDF && pdfLink && (
  <div
    className="fixed inset-0 bg-black bg-opacity-70 backdrop-blur-md  py-2 px-2 z-50 flex items-center justify-center"
    onClick={() => setShowPDF(false)}
  >
    <div
      className="relative bg-white w-full max-w-screen-lg h-full  md:py-6 md:px-6 max-h-screen rounded-lg shadow-xl overflow-hidden"
      style={{ padding: "16px" }} // Add padding to the container
      onClick={(e) => e.stopPropagation()}
    >
      {/* PDF Viewer */}
      <div className="w-full h-full overflow-auto">
        <PDFViewer fileUrl={pdfLink} darkMode={darkMode} />
      </div>

      {/* Close Button */}
      <button
        onClick={() => setShowPDF(false)}
        className="absolute top-4 right-4 bg-black text-white px-4 py-2 rounded-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-white"
      >
        &times;
      </button>
    </div>
  </div>
)}





      {/* Video Viewer */}
      {showVideo && videoLink && (
        <div
          className="fixed inset-0 bg-black bg-opacity-70 z-50 flex items-center justify-center"
          onClick={() => setShowVideo(false)}
        >
          <div
            className="bg-white w-3/4 h-3/4 p-4 rounded-lg shadow-lg"
            onClick={(e) => e.stopPropagation()}
          >
            <iframe
              width="100%"
              height="100%"
              src={`https://www.youtube.com/embed/${new URL(videoLink).searchParams.get("v")}`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
            <Button
              text="Close Video"
              variant="secondary"
              mode={darkMode ? "dark" : "light"}
              onClick={() => setShowVideo(false)}
              className="mt-4"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectCard;
