import PropTypes from "prop-types";
import React from "react";
import MacosControls from "./MacosControls";

interface Props {
  title: string;
  mode: "dark" | "light";
  className?: string;  // Optional className for additional styling
}

export const MacosWindowHeader = ({
  title = "New message",
  mode,
  className = "",
}: Props): JSX.Element => {
  return (
<div
   className={`w-full h-[54px] flex items-center justify-center rounded-t-lg relative 
     ${mode === "light" ? "shadow-[inset_0px_-1px_0px_#00000008] bg-[#f6f6f6]" : "shadow-[inset_0px_-1px_0px_#ffffff08] bg-[#181818]"} 
     ${className}`}
 >


        
      {/* MacOS control buttons aligned to the left */}
      <div className="absolute left-4 top-1/2 transform -translate-y-1/2">
        <MacosControls />
      </div>

      {/* Centered title in the header */}
      <div
        className={`text-base font-medium whitespace-nowrap
          ${mode === "light" ? "text-[#181818]" : "text-white"}`}
        style={{ fontFamily: "Inter-Medium, Helvetica" }}
      >
        {title}
      </div>
    </div>
  );
};

MacosWindowHeader.propTypes = {
  title: PropTypes.string,
  mode: PropTypes.oneOf(["dark", "light"]).isRequired,
  className: PropTypes.string,
};

export default MacosWindowHeader;
