import React from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { zoomPlugin } from "@react-pdf-viewer/zoom";

interface PDFViewerProps {
  fileUrl: string;
  className?: string;
  darkMode: boolean;
}

const PDFViewer: React.FC<PDFViewerProps> = ({ fileUrl, className, darkMode }) => {
  const zoomPluginInstance = zoomPlugin();
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;

  return (
    <div
      className={`pdf-viewer ${className} ${darkMode ? "text-white" : "text-black"}`}
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%", // Use the full height of the parent container
      }}
    >
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
        {fileUrl ? (
          <>
            <div style={{ flex: 1, overflow: "hidden" }}>
              <Viewer fileUrl={fileUrl} plugins={[zoomPluginInstance]} />
            </div>
            <div className="flex gap-2 justify-center mt-2">
              <ZoomOutButton />
              <ZoomPopover />
              <ZoomInButton />
            </div>
          </>
        ) : (
          <p className="text-red-500">Error: PDF file not found or invalid.</p>
        )}
      </Worker>
    </div>
  );
};

export default PDFViewer;
