import React, { useState, useEffect } from "react";
import Button from "./Button";

const CookieBanner: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);

 useEffect(() => {
  const userConsent = localStorage.getItem("cookieConsent");
  try {
    const consent = userConsent ? JSON.parse(userConsent) : null;
    if (!consent) {
      setIsVisible(true);
    }
  } catch (error) {
    console.error("Invalid consent data in localStorage. Resetting:", error);
    localStorage.removeItem("cookieConsent"); // Remove invalid data
    setIsVisible(true);
  }
}, []);

  const handleAccept = () => {
    const consentData = {
      status: "accepted",
      timestamp: new Date().toISOString(),
    };
    localStorage.setItem("cookieConsent", JSON.stringify(consentData));
    setIsVisible(false);
  };

  const handleNecessary = () => {
    const consentData = {
      status: "necessary",
      timestamp: new Date().toISOString(),
    };
    localStorage.setItem("cookieConsent", JSON.stringify(consentData));
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 z-50">
      {/* Dark Overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-80"></div>

      {/* Cookie Banner Content */}
      <div
        className="absolute left-0 w-full flex justify-center px-4"
        style={{ bottom: "80px" }} // Positioned 80px from the bottom
      >
        <div className="w-full max-w-xl bg-gray-800 text-white p-6 rounded-lg shadow-lg z-10 sm:max-w-md">
          <p className="mb-4 text-sm sm:text-xs text-center font-mono">
            I would like to use cookies to improve the browsing experience and analyze my traffic. Read my{" "}
            <a
              href="/privacy-policy"
              className="text-blue-400 underline hover:text-blue-600"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>{" "}
            to learn more.
          </p>
          <div className="flex flex-col sm:flex-row justify-center gap-2 sm:gap-4 font-mono">
            <Button
              text="Yes, I’m Okay with Cookies"
              variant="primary"
              mode="dark"
              onClick={handleAccept}
              className="text-xs sm:text-sm py-2 px-4 sm:py-3 sm:px-6"
            />
<button
  onClick={handleNecessary}
  className="font-mono text-xs sm:text-sm font-medium text-gray-300 underline hover:text-gray-400 cursor-pointer"
>
  Only Necessary
</button>

          </div>
        </div>
      </div>
    </div>
  );
};

export default CookieBanner;
