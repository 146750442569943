import React from "react";

interface FooterProps {
  className?: string;
  mode: "dark" | "light";
}

const Footer: React.FC<FooterProps> = ({ mode, className }) => {
  const isDarkMode = mode === "dark";
    return (
      <div
        className={`flex w-full items-center justify-center px-6  py-20 md:py-10  mx-auto border-t z-100
          ${isDarkMode ? "border-[#eeeeee29] text-[#7f7f7f]" : "border-[#dddddd] text-[#333333]"} 
          ${className || ""}
        `}
      >
        <p className="text-sm font-normal tracking-tight leading-normal">
          © 2024 LARS OBERHOFER. All Rights Reserved.
        </p>
      </div>
    );
  };
  
  export default Footer;
  