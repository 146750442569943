import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Responsive, WidthProvider } from "react-grid-layout";
import Header from "../components/Header";

import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

const ResponsiveGridLayout = WidthProvider(Responsive);

interface Note {
  _id?: string;
  title: string;
  content: string;
}

interface RssItem {
  title: string;
  link: string;
}

interface DashboardPageProps {
    mode: "dark" | "light";
  }
  

const DashboardPage: React.FC<DashboardPageProps> = ({mode}) => {
  const navigate = useNavigate();

  const [weatherData, setWeatherData] = useState<any>(null);
  const [notes, setNotes] = useState<Note[]>([]);
  const [fazNews, setFazNews] = useState<RssItem[]>([]);
  const [ftNews, setFtNews] = useState<RssItem[]>([]);

  // Grid layouts for large and small breakpoints
  const layouts = {
    lg: [
      { i: "weather", x: 0, y: 0, w: 4, h: 4 },
      { i: "notes",   x: 4, y: 0, w: 4, h: 4 },
      { i: "faz",     x: 8, y: 0, w: 4, h: 8 },
      { i: "ft",      x: 0, y: 3, w: 4, h: 8 },
    ],
    sm: [
      { i: "weather", x: 0, y: 0, w: 6, h: 4},
      { i: "notes",   x: 0, y: 3, w: 6, h: 4 },
      { i: "faz",     x: 0, y: 7, w: 6, h: 8 },
      { i: "ft",      x: 0, y: 11, w: 6, h: 8},
    ],
  };

  // Check authentication
  useEffect(() => {
    fetch("https://website-backend-rid1.onrender.com/api/auth/secret", {
      credentials: "include",
    })
      .then((res) => {
        if (!res.ok) throw new Error("Not authenticated");
        return res.json();
      })
      .then((data) => console.log("Authenticated user:", data))
      .catch(() => {
        console.warn("Redirecting to login...");
        navigate("/login");
      });
  }, [navigate]);

  // Fetch all data (weather, notes, FAZ, FT)
  useEffect(() => {
    // Weather
    fetch("https://website-backend-rid1.onrender.com/api/dashboard/weather", {
      credentials: "include",
    })
      .then((res) => res.json())
      .then(setWeatherData)
      .catch(console.error);

    // Notes
    fetch("https://website-backend-rid1.onrender.com/api/dashboard/notes", {
      credentials: "include",
    })
      .then((res) => res.json())
      .then(setNotes)
      .catch(console.error);

    // FAZ
    fetch("https://website-backend-rid1.onrender.com/api/dashboard/faz", {
      credentials: "include",
    })
      .then((res) => res.json())
      .then((feed) => {
        if (feed?.items) setFazNews(feed.items);
      })
      .catch(console.error);

    // FT
    fetch("https://website-backend-rid1.onrender.com/api/dashboard/ft", {
      credentials: "include",
    })
      .then((res) => res.json())
      .then((feed) => {
        if (feed?.items) setFtNews(feed.items);
      })
      .catch(console.error);
  }, []);

  // Add a note
  const handleAddNote = async () => {
    const newNote: Note = { title: "New Note", content: "Content goes here" };
    try {
      const res = await fetch(
        "https://website-backend-rid1.onrender.com/api/dashboard/notes",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
          body: JSON.stringify(newNote),
        }
      );
      if (res.ok) {
        const createdNote = await res.json();
        // Append to local state
        setNotes((prev) => [...prev, createdNote]);
      } else {
        console.error("Failed to save note:", res.status);
      }
    } catch (err) {
      console.error("Error adding note:", err);
    }
  };

  // Logout
  const handleLogout = async () => {
    await fetch("https://website-backend-rid1.onrender.com/api/auth/logout", {
      method: "POST",
      credentials: "include",
    });
    navigate("/login");
  };

  return (
    <div className="relative">
      {/* Simulating the InfoPage style header with py-32, etc. */}
      <div className="container mx-auto py-32 flex flex-col max-w-screen-lg space-y-16">
        {/* Header row with logout */}
        <div className="flex justify-between items-center">
          <Header
            mode={mode}
            text="My Personalized Dashboard"
            subtext="All your feeds in one place"
          />
          <button
            onClick={handleLogout}
            className="px-4 py-2 bg-red-500 text-white rounded-lg shadow-md hover:bg-red-600"
          >
            Logout
          </button>
        </div>
      </div>

      {/* A divider line, if you want it */}
      <div className="absolute left-0 w-screen border-t border-[#dddddd]" />

      {/* MAIN content area for the grid */}
      <div className="container mx-auto max-w-screen-lg py-16 px-4">
        <ResponsiveGridLayout
          className="layout"
          layouts={layouts}
          breakpoints={{ lg: 1200, sm: 768 }}
          cols={{ lg: 12, sm: 6 }}
          rowHeight={30}
          isResizable
          isDraggable
        >
          {/* Weather Widget */}
          <div key="weather" className="bg-white border border-gray-200 shadow-md rounded-lg p-4 space-y-2">
  <h2 className="font-sans text-xl font-bold text-black">Weather</h2>
  {weatherData ? (
    <div className="font-mono">
      <p>Temperature: {weatherData.main?.temp}°C</p>
      <p>Condition: {weatherData.weather?.[0]?.description}</p>
      {/* Sunrise / Sunset */}
      <p>
        Sunrise:{" "}
        {new Date(weatherData.sys?.sunrise * 1000).toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
        })}
      </p>
      <p>
        Sunset:{" "}
        {new Date(weatherData.sys?.sunset * 1000).toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
        })}
      </p>
    </div>
  ) : (
    <p>Loading weather...</p>
  )}
</div>


          {/* Notes Widget */}
          <div
            key="notes"
            className="bg-white border border-gray-200 shadow-md rounded-lg p-4 space-y-2"
          >
            <div className="flex justify-between items-center">
              <h2 className="font-sans text-xl font-bold text-black">Notes</h2>
              <button
                onClick={handleAddNote}
                className="px-3 py-1 bg-blue-500 text-white rounded shadow-md hover:bg-blue-600"
              >
                + Add Note
              </button>
            </div>
            <div className="space-y-3">
              {notes.length > 0 ? (
                notes.map((note) => (
                  <div
                    key={note._id}
                    className="p-3 border border-gray-200 rounded-lg font-mono"
                  >
                    <h3 className="font-semibold">{note.title}</h3>
                    <p>{note.content}</p>
                  </div>
                ))
              ) : (
                <p>No notes yet.</p>
              )}
            </div>
          </div>

          {/* FAZ Widget */}
          <div
            key="faz"
            className="bg-white border border-gray-200 shadow-md rounded-lg p-4 space-y-2 overflow-y-auto"
          >
            <h2 className="font-sans text-xl font-bold text-black">FAZ News</h2>
            {fazNews.length > 0 ? (
              <ul className="space-y-2 list-disc list-inside font-mono">
                {fazNews.map((item, idx) => (
                  <li key={idx}>
                    <a
                      href={item.link}
                      target="_blank"
                      rel="noreferrer"
                      className="text-blue-600 hover:underline"
                    >
                      {item.title}
                    </a>
                  </li>
                ))}
              </ul>
            ) : (
              <p>Loading FAZ feed...</p>
            )}
          </div>

          {/* Financial Times Widget */}
          <div
            key="ft"
            className="bg-white border border-gray-200 shadow-md rounded-lg p-4 space-y-2 overflow-y-auto"
          >
            <h2 className="font-sans text-xl font-bold text-black">Financial Times</h2>
            {ftNews.length > 0 ? (
              <ul className="space-y-2 list-disc list-inside font-mono">
                {ftNews.map((item, idx) => (
                  <li key={idx}>
                    <a
                      href={item.link}
                      target="_blank"
                      rel="noreferrer"
                      className="text-blue-600 hover:underline"
                    >
                      {item.title}
                    </a>
                  </li>
                ))}
              </ul>
            ) : (
              <p>Loading FT feed...</p>
            )}
          </div>
        </ResponsiveGridLayout>
      </div>
    </div>
  );
};

export default DashboardPage;
