import React from "react";
import BackgroundBlur from "../components/BackgroundBlur";
import InfoSection from "../components/InfoSection";
import SocialLinks from "../components/SocialLinks";
import ContactForm from "../components/ContactForm";
import Header from "../components/Header";
import Footer from "../components/Footer";

interface InfoPageProps {
  mode: "dark" | "light";
}

const InfoPage: React.FC<InfoPageProps> = ({ mode }) => {
  return (
    <div
      className={`relative ${

        mode === "dark" ?"text-text-light" : "text-text-dark"
      }`}
    >
      {/* Background Blur */}
      <BackgroundBlur />

      {/* Main Content Container */}
      <div className="container mx-auto py-32 flex flex-col max-w-screen-lg space-y-16">
        {/* Header Section */}
        <Header
          mode={mode}
          text="ABOUT ME"
          subtext="Welcome, I am happy you are here."
        />
      </div>

      {/* Divider */}
      <div
        className={`absolute left-0 w-screen border-t ${
          mode === "dark" ? "border-[#eeeeee29]" : "border-[#dddddd]"
        }`}
      ></div>

      {/* Info Sections */}
      <div className="leading-[normal] container mx-auto flex flex-col gap-16 max-w-screen-lg py-16">
        <InfoSection
          title="WHO I AM"
          description="I'm Lars Oberhofer, a creative Human-Computer Interaction specialist with a passion for UX/UI design and engineering, looking for the next big challenge. Originally from a sunny place at the southern German wine street, now in the cold but beautiful Finland."
          mode={mode}
          />
        <InfoSection
                  mode={mode}

          title="WHAT I DO"
          description="My work is connecting different passions of mine, from engineering, research, and Human-Computer Interaction. Enjoy making things better, with a human-centered perspective, I'm curious about learning more about AI while leveraging my background in Electrical Engineering and Computer Science."
        />
        <InfoSection
                  mode={mode}

          title="WHAT I DID"
          description="I earned my BSc in Electrical Engineering from the University of Twente in the Netherlands and pursued a dual master’s degree in Human-Computer Interaction at Paris-Saclay and Aalto University, graduating Cum Laude. About this site: It’s built with React, styled with Tailwind CSS, animated with Framer Motion, and entirely designed in Figma."
        />
      </div>

      {/* Contact Form Section */}
      <div className="container mx-auto flex flex-col gap-16 max-w-screen-lg px-2 py-12">
        <Header
          mode={mode}
          text="Get in Touch"
          subtext="Let’s build something awesome."
        />
        <ContactForm mode={mode} />
      </div>

      {/* Social Links */}
      <div>
        <SocialLinks mode={mode} />
      </div>

      {/* Footer */}
      <Footer mode={mode} className="relative z-10 mt-12" />
      </div>
  );
};

export default InfoPage;
