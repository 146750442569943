import React from "react";
import BackgroundBlur from "../components/BackgroundBlur";
import Header from "../components/Header";
import Footer from "../components/Footer";

interface PrivacyPolicyProps {
  mode: "dark" | "light";
}

const PrivacyPolicy: React.FC<PrivacyPolicyProps> = ({ mode }) => {
  return (
    <div
      className={`relative min-h-screen flex flex-col items-center ${
        mode === "dark" ? "bg-[#0d0d0d] text-white" : "bg-[#f9f9f9] text-black"
      }`}
    >
      {/* Background Blur */}
      <BackgroundBlur />

      {/* Content Container */}
      <div className="container mx-auto py-32 flex flex-col items-center max-w-screen-lg space-y-16">
        {/* Header Section */}
        <div className="w-full mb-8">
          <Header
            mode={mode}
            text="Privacy Policy"
            subtext="Your privacy is important to me."
          />
        </div>

        {/* Privacy Policy Sections */}
        <div className="flex flex-col gap-16 w-full font-mono">
          <section>
            <h2 className="text-xl font-semibold mb-4">Introduction</h2>
            <p>
              At Lars Oberhofer's website, I am committed to protecting your
              privacy. This Privacy Policy outlines how I collect, use, and
              protect your information in compliance with GDPR.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">What Information I Collect</h2>
            <ul className="list-disc list-inside">
              <li>
                <strong>Personal Information:</strong> When you contact me, I may collect your name, email address, and
                any other details you provide.
              </li>
              <li>
                <strong>Usage Data:</strong> Through cookies and tracking scripts,
                I collect information about your interactions with my website,
                such as pages visited, time spent, and browser details.
              </li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">Cookies and Tracking</h2>
            <p>
              My website uses cookies for essential functionality (e.g., 
              maintaining your session and improving security) and analytics (e.g., Google Tag Manager) 
              to understand website performance and user behavior.               
              Cookies for analytics are only enabled if you provide explicit consent via the cookie banner displayed when you first visit the site.
            </p>
            <p> Declining cookies will disable analytics and tracking functionality. 
                If you wish to update or withdraw your consent after making a selection, you may need to clear your browser's local storage or cookies to reset your preferences. 
                For more information on managing cookies, refer to your browser's help documentation.

            </p>


          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">Your Rights</h2>
            <ul className="list-disc list-inside">
              <li>
                <strong>Access:</strong> Request a copy of the data I have
                collected about you.
              </li>
              <li>
                <strong>Rectification:</strong> Correct any inaccuracies in your
                data.
              </li>
              <li>
                <strong>Erasure:</strong> Request that I delete your data.
              </li>
            </ul>
            <p>
              To exercise your rights or if you have questions about this Privacy Policy, 
              please visit my{" "}
              <a
                href="/info"
                className="text-blue-500 underline hover:text-blue-700"
              >
                contact page
              </a>
              .
            </p>
          </section>

          <p className="text-sm text-gray-500">
            Effective Date: {new Date().toLocaleDateString()}
          </p>
        </div>
      </div>

      {/* Footer */}
      <Footer mode={mode} className="relative z-10 mt-12" />

    </div>
  );
};

export default PrivacyPolicy;
