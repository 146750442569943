import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { motion, useMotionValue, useSpring } from "framer-motion";

interface Props {
  mode: "dark" | "light";
  className?: string;
  hidden?: boolean;
}

export const LinksWeb = ({ mode, className, hidden = false }: Props): JSX.Element => {
  const location = useLocation();

  // Motion value to control opacity and translateY dynamically
  const opacity = useMotionValue(1);
  const translateY = useMotionValue(0);

  // State to track the last scroll position and direction
  const [lastScrollY, setLastScrollY] = useState(0);

  // Handle scroll direction and apply animations
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > lastScrollY) {
        // Scrolling down: fade out and slide up
        opacity.set(0);
        translateY.set(-50);
      } else {
        // Scrolling up: fade in and slide back to original position
        opacity.set(1);
        translateY.set(0);
      }

      setLastScrollY(currentScrollY);
    };

    // Attach scroll event listener
    window.addEventListener("scroll", handleScroll);

    return () => {
      // Cleanup scroll event listener
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY, opacity, translateY]);

  // Base styles for links
  const linkStyles = `pointer-events-auto text-[22px] fixed leading-normal transition-transform duration-300 px-4 py-2 rounded-lg
    ${
      mode === "dark"
        ? "text-basiconyx bg-[rgba(255,255,255,0.1)]"
        : "text-white bg-[rgba(0,0,0,0.2)]"
    }
    backdrop-blur-md hover:backdrop-blur-lg`;

  // Gradient mask for smooth fading
  const gradientMask = {
    maskImage: "linear-gradient(180deg, rgba(0, 0, 0, 1) 70%, rgba(0, 0, 0, 0))",
    WebkitMaskImage:
      "linear-gradient(180deg, rgba(0, 0, 0, 1) 70%, rgba(0, 0, 0, 0))",
    maskRepeat: "no-repeat",
    WebkitMaskRepeat: "no-repeat",
    maskSize: "100% 100%",
    WebkitMaskSize: "100% 100%",
  };

  // Function to determine if the current link is active
  const isActive = (path: string) => location.pathname === path;

  return (
    <motion.div
      style={{
        opacity: useSpring(opacity, { stiffness: 300, damping: 30 }), // Smooth transition
        translateY: useSpring(translateY, { stiffness: 300, damping: 30 }), // Smooth slide
      }}
      className={`${hidden ? "hidden" : ""} fixed inset-0 z-50 pointer-events-none ${className || ""}`}
    >
      {/* Portfolio Link - Top Right */}
      <motion.div
        className={`${linkStyles} top-4 right-8 ${
          isActive("/creations") ? "underline" : "hover:scale-105"
        }`}
        style={gradientMask}
      >
        <Link to="/creations">PORTFOLIO</Link>
      </motion.div>

      {/* Lars Oberhofer Link - Top Left */}
      <motion.div
        className={`${linkStyles} top-4 left-8 ${
          isActive("/") ? "underline" : "hover:scale-105"
        }`}
        style={gradientMask}
      >
        <Link to="/">LARS OBERHOFER</Link>
      </motion.div>

      {/* Get In Touch Link - Bottom Left */}
      <motion.div
        className={`${linkStyles} bottom-4 left-8 ${
          isActive("/info") ? "underline" : "hover:scale-105"
        }`}
        style={gradientMask}
      >
        <Link to="/info">GET IN TOUCH</Link>
      </motion.div>

      {/* Photography Link - Bottom Right */}
      <motion.div
        className={`${linkStyles} bottom-4 right-8 ${
          isActive("/photography") ? "underline" : "hover:scale-105"
        }`}
        style={gradientMask}
      >
        <Link to="/photography">PHOTOGRAPHY</Link>
      </motion.div>
    </motion.div>
  );
};

LinksWeb.propTypes = {
  mode: PropTypes.oneOf(["dark", "light"]),
  hidden: PropTypes.bool,
};

export default LinksWeb;
