const BackgroundBlur = (): JSX.Element => {
  return (
    <div className="absolute inset-0 -z-1">
      {/* Top Shapes */}
      <div className="absolute w-full h-[550px] top-0 left-0 overflow-hidden">
        <div className="relative w-[480px] sm:w-[768px] lg:w-[1012px] h-[150px] sm:h-[200px] lg:h-[263px] mx-auto top-[-40px] sm:top-[-60px] lg:top-[-83px]">
          {/* Yellow Shape */}
          <div className="absolute w-[200px] h-[40px] bg-[#FFB700] opacity-100 rounded-[299.32px/69.06px] blur-[40px] sm:w-[400px] sm:h-[70px] sm:blur-[100px] lg:w-[500px] lg:h-[90px] lg:blur-[120px] right-[10px] sm:right-[-40px] lg:right-[-60px]" />
          {/* Blue Shape */}
          <div className="absolute w-[200px] h-[40px] bg-[#3F64E8] opacity-100 rounded-[299.32px/69.06px] blur-[40px] sm:w-[400px] sm:h-[70px] sm:blur-[100px] lg:w-[500px] lg:h-[90px] lg:blur-[120px] left-[20px] sm:left-[10px] lg:left-[20px]" />
          {/* Red Shape */}
          <div className="absolute w-[200px] h-[40px] bg-[#E83F3F] opacity-100 rounded-[299.32px/69.06px] blur-[40px] sm:w-[400px] sm:h-[70px] sm:blur-[100px] lg:w-[500px] lg:h-[90px] lg:blur-[120px] right-[60px] sm:right-[120px] lg:right-[190px]" />
        </div>
      </div>

      {/* Bottom Shapes */}
      <div className="absolute w-full h-[550px] bottom-0 left-0 overflow-hidden">
        <div className="relative w-[480px] sm:w-[768px] lg:w-[1012px] h-[150px] sm:h-[200px] lg:h-[263px] mx-auto bottom-[-450px] sm:bottom-[-380px] lg:bottom-[-390px]">
          {/* Yellow Shape */}
          <div className="absolute w-[200px] h-[40px] bg-[#FFB700] opacity-100 rounded-[299.32px/69.06px] blur-[40px] sm:w-[400px] sm:h-[70px] sm:blur-[100px] lg:w-[500px] lg:h-[90px] lg:blur-[120px] left-[10px] sm:left-[10px] lg:left-[20px] bottom-[10px] sm:bottom-[10px] lg:bottom-[20px]" />
          {/* Blue Shape */}
          <div className="absolute w-[200px] h-[40px] bg-[#3F64E8] opacity-100 rounded-[299.32px/69.06px] blur-[40px] sm:w-[400px] sm:h-[70px] sm:blur-[100px] lg:w-[500px] lg:h-[90px] lg:blur-[120px] right-[10px] sm:right-[-40px] lg:right-[-60px] bottom-[60px] sm:bottom-[80px] lg:bottom-[100px]" />
          {/* Red Shape */}
          <div className="absolute w-[200px] h-[40px] bg-[#E83F3F] opacity-100 rounded-[299.32px/69.06px] blur-[40px] sm:w-[400px] sm:h-[70px] sm:blur-[100px] lg:w-[500px] lg:h-[90px] lg:blur-[120px] left-[60px] sm:left-[120px] lg:left-[190px] bottom-[120px] sm:bottom-[140px] lg:bottom-[160px]" />
        </div>
      </div>
    </div>
  );
};

export default BackgroundBlur;








