import React from "react";
import { useParams } from "react-router-dom";
import BackgroundBlur from "../components/BackgroundBlur";
import Header from "../components/Header";
import Footer from "../components/Footer";
import BachelorThesisContent from "../components/BachelorThesisContent";
import MasterThesisContent from "../components/MasterThesisContent";

interface ProjectPageLayoutProps {
  mode: "dark" | "light";

}

const ProjectPageLayout: React.FC<ProjectPageLayoutProps> = ({ mode }) => {
  const { id } = useParams<{ id: string }>(); // Get dynamic project ID from URL
  const isLightMode = mode === "light";


  const renderProjectContent = () => {
    switch (id) {
      case "bachelor-thesis-evtol":
        return <BachelorThesisContent mode={mode} />;
      case "master-thesis-abb":
        return <MasterThesisContent mode={mode} />;
      default:
        return (
          <div className={`text-center ${
                    isLightMode ? "text-text-light" : "text-text-dark"
              }`}>
            
            <h1>Project Not Yet Published</h1>
            <p>The project you are looking for will be available shortly.</p>
          </div>
        );
    }
  };
  
  return (
    <div
      className={`relative min-h-screen flex flex-col ${
        mode === "dark" ? "bg-[#1a1a1a]" : "bg-[#f9f9f9]"
      }`}
    >
      {/* Background Blur */}
      
      <div className="absolute inset-0">
        <BackgroundBlur />
      </div>


      {/* Page Content */}
      <div className="relative">
        {/* Header */}
        <div className="container mx-auto py-32 flex flex-col items-start max-w-screen-lg">
          <Header
            mode={mode}
            text="SELECTED WORK"
            subtext="Project Details"
          />
        </div>

        {/* Divider */}
        <div
          className={`w-full border-t ${
            mode === "dark" ? "border-[#eeeeee29]" : "border-[#dddddd]"
          }`}
        ></div>

        {/* Dynamic Project Content */}
        <div
          className={`flex-1 px-2 sm:px-6  py-12 mb-12 ${
            mode === "dark" ? "text-white" : "text-black"}`}
        >
          {renderProjectContent()}
        </div>

        {/* Footer */}
        <Footer mode={mode} className="relative z-10" />
      </div>
    </div>
  );
};

export default ProjectPageLayout;
