import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

interface LoginPageProps {
  onLogin: () => void; // If you still need to notify a parent component of successful login
}

const LoginPage: React.FC<LoginPageProps> = ({ onLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();  
    setError(null);

    try {
      console.log("Attempting login with:", { username, password });
      const response = await fetch("https://website-backend-rid1.onrender.com/api/auth/login", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ username, password }),
        credentials: "include",
      });

      console.log("Login response status:", response.status);

      if (!response.ok) {
        const { message } = await response.json();
        console.error("Login failed with message:", message);
        throw new Error(message || "Login failed");
      }

      console.log("Login successful. Calling onLogin callback...");
      onLogin?.(); // If needed, notify parent of successful login

      // Redirect the user to your dashboard route
      console.log("Navigating to /dashboard...");
      navigate("/dashboard");
    } catch (err: any) {
      console.error("Error during login:", err.message || err);
      setError(err.message || "An unexpected error occurred.");
    }
  };

  const handleSignUp = () => {
    navigate("/signup");
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <h1 className="text-2xl font-bold mb-4">Login</h1>
      {error && <p className="text-red-500 mb-2">{error}</p>}
      <form onSubmit={handleLogin} className="flex flex-col items-center w-full max-w-sm">
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className="mb-2 px-4 py-2 border rounded w-full"
          required
          autoComplete="username"
          aria-label="Username"
        />
        <input
          type="password"
          placeholder="Password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="mb-4 px-4 py-2 border rounded w-full"
          required
          aria-label="Password"
        />
        <button
          type="submit"
          className="px-4 py-2 bg-blue-500 text-white rounded-lg w-full mb-2"
        >
          Log In
        </button>
      </form>
      <p className="mb-2 text-gray-700">Don't have an account?</p>
      <button
        onClick={handleSignUp}
        className="px-4 py-2 bg-green-500 text-white rounded-lg"
      >
        Sign Up
      </button>
    </div>
  );
};

export default LoginPage;
