import React from "react";

interface InfoSectionProps {
  title: string;
  description: string;
  mode: "dark" | "light";
}

const InfoSection: React.FC<InfoSectionProps> = ({ title, description, mode }) => {
  const isLightMode = mode === "light";

  return (
    <div className="flex flex-col items-start gap-2 px-4">
      {/* Title */}
      <div
        className={`text-sm sm:text-base md:text-lg lg:text-xl font-semibold ${
          isLightMode ? "text-[#181818]" : "text-white"
        }`}
      >
        {title}
      </div>
         {/* Description */} 
         <div
        className={`text-sm sm:text-text-sm md:text-base lg:text-lg font-mono  ${
          isLightMode ? "text-[#313131]" : "text-[#b4b4b4]"
        }`}
      >
        {description}
      </div>
    </div>
  );
};

export default InfoSection;
