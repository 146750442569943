import React, { useState, useEffect } from "react";
import {
  Routes,
  Route,
  useLocation,
  Navigate,
  useNavigate,
} from "react-router-dom";
import HomePage from "./pages/HomePage";
import PhotographyPage from "./pages/PhotographyPage";
import InfoPage from "./pages/InfoPage";
import ProjectPage from "./pages/ProjectPage";
import ProjectPageLayout from "./pages/ProjectPageLayout";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import LoginPage from "./pages/LoginPage";
import SignUpPage from "./pages/SignUpPage";
import DashboardPage from "./pages/DashboardPage";
import "./global.css";

// Components
import Toggle from "./components/Toggle";
import LinksWeb from "./components/LinksWeb";
import Tabbar from "./components/Tabbar";
import CookieBanner from "./components/CookieBanner";
import ScrollToTop from "./components/ScrollToTop";

const App: React.FC = () => {
  const [mode, setMode] = useState<"dark" | "light">("dark");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  // Toggles theme
  const toggleMode = () => {
    setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
  };

  // Runs once to check user auth at startup
  useEffect(() => {
    const checkAuthOnce = async () => {
      console.log("Checking authentication once on mount...");
      try {
        const response = await fetch(
          "https://website-backend-rid1.onrender.com/api/auth/secret",
          {
            credentials: "include", 
          }
        );
        if (!response.ok) {
          console.warn("User not authenticated.");
          throw new Error("Authentication failed");
        }
        console.log("User authenticated successfully.");
        setIsAuthenticated(true);
      } catch (error) {
        console.error("Authentication error:", error);
        setIsAuthenticated(false);
      }
    };
    checkAuthOnce();
  }, []);

  // “Login” callback (just sets local state)
  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  // “Logout” callback
  const handleLogout = async () => {
    await fetch("https://website-backend-rid1.onrender.com/api/auth/logout", {
      method: "POST",
      credentials: "include",
    });
    setIsAuthenticated(false);
    navigate("/");
  };

  // Fullscreen event
  const handleFullscreenChange = (isFullscreen: boolean) => {
    setFullscreen(isFullscreen);
  };

  // ProtectedRoute: if not auth, redirect to /login
  const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
    if (!isAuthenticated) {
      console.log("User is not authenticated. Redirecting to /login...");
      return <Navigate to="/login" replace />;
    }
    return children;
  };

  // Conditionally show cookie banner
  const showCookieBanner =
    !localStorage.getItem("cookieConsent") &&
    location.pathname !== "/privacy-policy";

  // Dynamic background gradient
  const pageGradientClass = mode === "dark" ? "bg-[#1e1e1e]" : "bg-[#f9f9f9]";

  return (
    <div className={`page-gradient ${pageGradientClass}`}>
      <ScrollToTop />

      {showCookieBanner && <CookieBanner />}

      {/* Theme Toggle */}
      <Toggle onClick={toggleMode} mode={mode} />

      {/* Desktop Links */}
      <div className="hidden md:block fixed top-0 left-0 w-full z-50">
        <LinksWeb mode={mode === "dark" ? "light" : "dark"} />
      </div>

      {/* Mobile Tabbar */}
      <div className="block md:hidden fixed top-0 left-0 w-full z-30">
        <Tabbar mode={mode} />
      </div>

      <Routes>
        <Route path="/" element={<HomePage mode={mode} />} />
        <Route
          path="/photography"
          element={
            <PhotographyPage mode={mode} onFullscreenChange={handleFullscreenChange} />
          }
        />
        <Route path="/info" element={<InfoPage mode={mode} />} />
        <Route path="/creations" element={<ProjectPage mode={mode} />} />
        <Route path="/project/:id" element={<ProjectPageLayout mode={mode} />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy mode={mode} />} />

        {/* Auth */}
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/login" element={<LoginPage onLogin={handleLogin} />} />

        {/* Example protected route #1: /dashboard */}
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <DashboardPage mode={mode} />
            </ProtectedRoute>
          }
        />

        {/* Example protected route #2: /secret */}
        <Route
          path="/secret"
          element={
            <ProtectedRoute>
              {/* Could render a different page or re-use Dashboard */}
              <DashboardPage mode={mode} />
            </ProtectedRoute>
          }
        />
      </Routes>
    </div>
  );
};

export default App;
