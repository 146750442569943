//import YouTube from "../assets/svg//SocialLinks/YouTube.svg";
//import Twitch from "../assets/svg//SocialLinks/Twitch.svg";
//import Reddit from "../assets/svg//SocialLinks/Reddit.svg";
//import Facebook from "../assets/svg//SocialLinks/Facebook.svg";
//import Pinterest from "../assets/svg//SocialLinks/Pinterest.svg";
//import Snapchat from "../assets/svg//SocialLinks/Snapchat.svg";
//import TikTok from "../assets/svg//SocialLinks/TikTok.svg";
//import WhatsApp from "../assets/svg//SocialLinks/WhatsApp.svg";
//import Signal from "../assets/svg//SocialLinks/Signal.svg";
//import Skype from "../assets/svg//SocialLinks/Skype.svg";
//import Slack from "../assets/svg//SocialLinks/Slack.svg";
//import Zoom from "../assets/svg//SocialLinks/Zoom.svg";
//import MicrosoftTeams from "../assets/svg//SocialLinks/MicrosoftTeams.svg";
//import GoogleMeet from "../assets/svg//SocialLinks/GoogleMeet.svg";
//import FaceTime from "../assets/svg//SocialLinks/FaceTime.svg";
//import Duo from "../assets/svg//SocialLinks/Duo.svg";
//import Viber from "../assets/svg//SocialLinks/Viber.svg";
//import Line from "../assets/svg//SocialLinks/Line.svg";
//import WeChat from "../assets/svg//SocialLinks/WeChat.svg";
//import QQ from "../assets/svg//SocialLinks/QQ.svg";

import Telegram from "../assets/svg//SocialLinks/Send.svg";
import Email from "../assets/svg//SocialLinks/Email.svg";
import GitHub from "../assets/svg//SocialLinks/Github.svg";
import LinkedIn from "../assets/svg//SocialLinks/LinkedIn.svg";
import Twitter from "../assets/svg//SocialLinks/X.svg";
import Instagram from "../assets/svg//SocialLinks/Instagram.svg";


interface SocialLinksProps {
  mode: "dark" | "light";
}

const SocialLinks: React.FC<SocialLinksProps> = ({ mode }) => {
  // Determine the icon color style for dark mode
  const iconStyle = mode === "dark" ? "filter invert-[90%]" : "";

  return (
    <div className="flex gap-4 justify-center pb-16">
      {/* Instagram */}
      <a href="https://www.instagram.com/aroomforrent" target="_blank" rel="noopener noreferrer">
        <img src={Instagram} alt="Instagram" className={`w-6 h-6 ${iconStyle}`} />
      </a>

      {/* LinkedIn */}
      <a href="https://www.linkedin.com/in/lars-oberhofer" target="_blank" rel="noopener noreferrer">
        <img src={LinkedIn} alt="LinkedIn" className={`w-6 h-6 ${iconStyle}`} />
      </a>

      {/* Twitter (X) */}
      <a href="https://x.com/LarsOberhofer" target="_blank" rel="noopener noreferrer">
        <img src={Twitter} alt="Twitter (X)" className={`w-6 h-6 ${iconStyle}`} />
      </a>

      {/* Email */}
      <a href="mailto:l.oberhofer@web.de" target="_blank" rel="noopener noreferrer">
        <img src={Email} alt="Email" className={`w-6 h-6 ${iconStyle}`} />
      </a>

      {/* Telegram */}
      <a href="https://t.me/larrsoo" target="_blank" rel="noopener noreferrer">
        <img src={Telegram} alt="Telegram" className={`w-6 h-6 ${iconStyle}`} />
      </a>

      {/* GitHub */}
      <a href="https://github.com/obywan-afk" target="_blank" rel="noopener noreferrer">
        <img src={GitHub} alt="GitHub" className={`w-6 h-6 ${iconStyle}`} />
      </a>
    </div>
  );
};

export default SocialLinks;