import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const SignUpPage: React.FC = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();

  const handleSignUp = async (e: React.FormEvent) => {
    e.preventDefault(); // Prevent default form submission
    setError(""); // Reset error state
    setSuccess(""); // Reset success state
  
    console.log("Attempting to sign up with username:", username);
  
    if (!username || !password) {
      setError("Username and password are required.");
      return;
    }
  
    try {
      const response = await fetch("https://website-backend-rid1.onrender.com/api/auth/signup", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ username, password }),
      });
  
      console.log("Signup API response status:", response.status);
  
      if (!response.ok) {
        const data = await response.json();
        console.error("Signup failed with message:", data.message);
        throw new Error(data.message || "Sign-up failed");
      }
  
      setSuccess("User registered successfully! Please log in.");
      console.log("Signup successful for username:", username);
  
      // Clear form fields after successful signup
      setUsername("");
      setPassword("");
  
      // Redirect after a delay
      setTimeout(() => navigate("/login"), 2000);
    } catch (err: any) {
      console.error("An error occurred during signup:", err.message);
      setError(err.message || "An unexpected error occurred. Please try again.");
    }
  };
  
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <h1 className="text-2xl font-bold mb-4">Sign Up</h1>
      {error && <p className="text-red-500 mb-2">{error}</p>}
      {success && <p className="text-green-500 mb-2">{success}</p>}
      <form onSubmit={handleSignUp} className="flex flex-col items-center w-full max-w-sm">
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className="mb-2 px-4 py-2 border rounded w-full"
          required
        />
        <input
          type="password"
          placeholder="Password"
          autoComplete="current-password" // Add this attribute
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="mb-4 px-4 py-2 border rounded w-full"
          required
        />
        <button
          type="submit"
          className="px-4 py-2 bg-blue-500 text-white rounded-lg w-full"
        >
          Sign Up
        </button>
      </form>
      <button
        onClick={() => navigate("/login")}
        className="px-4 py-2 bg-gray-500 text-white rounded-lg mt-4 w-full"
      >
        Back to Login
      </button>
    </div>
  );
};

export default SignUpPage;