import React from "react";

interface Props {
  className?: string;
}

const BackgroundGradient = ({ className }: Props): JSX.Element => {
  return (
    <div className={`absolute inset-0 ${className}`}>
      {/* Yellow Shape - Fixed size and position */}
      <div className="absolute w-[500px] h-[90px] top-[-30px] right-[-50px] bg-[#FFB700] opacity-100 rounded-full blur-[100px]" />

      {/* Blue Shape - Fixed size and position */}
      <div className="absolute w-[500px] h-[90px] bottom-[-50px] left-[-50px] bg-[#3F64E8] opacity-100 rounded-full blur-[100px]" />
      
      {/* Red Shape - Fixed size and position */}
      <div className="absolute w-[500px] h-[90px] bottom-[-50px] right-[-50px] bg-[#E83F3F] opacity-100 rounded-full blur-[100px]" />
    </div>
  );
};

export default BackgroundGradient;
