import React, { useState, useEffect } from "react";
import BackgroundGradient from "../components/BackgroundGradient";
import pictureofme from "../assets/png/pictureofme.jpg";
import UIEngineer1_b from "../assets/png/UIEngineer_black.png";
import UIEngineer1_w from "../assets/png/UIEngineer_white.png";

interface Props {
  mode: "dark" | "light";
}

const HomePage = ({ mode }: Props): JSX.Element => {
  const isLightMode = mode === "light";
  const imageSrc = mode === "dark" ? UIEngineer1_w : UIEngineer1_b;

  // State to track screen size
  const [screenSize, setScreenSize] = useState<number>(window.innerWidth);

  useEffect(() => {
    // Update state on window resize
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Determine the layout based on screen size
  const getLayout = () => {
    if (screenSize >= 1024) {
      // Large Screen Layout
      return (
        <div className="flex items-center pl-0 pr-[65px] py-0 relative">

          {/* Left Section */}
          <div className="flex-col min-w-[690px]  mt-[30px] items-start justify-center gap-[20px] px-0 py-[3px] flex-1 self-stretch grow flex relative">
           
           
            {/* Welcome Text */}
            
              
            <div className={`flex items-center justify-end gap-2.5 py-0 self-stretch w-full flex-[0_0_auto]`}
              style={{
                minHeight: "30px", // Keep minimum height
                paddingRight: "41%", // Apply 40% of the parent width as left padding
                paddingLeft: "7%", // Keep consistent 70px right padding
              }}
            >
              
              
              <p
                className={`relative  font-mono text-[19px] leading-tight max-w-[690px] text-justify ${
                  isLightMode ? "text-text-light" : "text-text-dark"
                }`}
              >
                Welcome to my digital space! Here, you’ll find my portfolio
                showcasing design projects and some analog photography. I’m a
              </p>
            </div>

            {/* UI Engineer Image */}
              <img
                className="w-full h-auto object-contain" // Height adjusts based on the image width
                alt={`UI Engineer set in ${mode} mode`}
                src={imageSrc}
              />

          

            <div className={`flex items-center justify-end gap-2.5 py-0 self-stretch w-full flex-[0_0_auto]`}
              style={{
                minHeight: "30px", // Keep minimum height
                paddingLeft: "41%", // Apply 40% of the parent width as left padding
                paddingRight: "7%", // Keep consistent 70px right padding
              }}
            >
              <p
                className={`relative font-mono font-normal text-[19px] leading-tight max-w-[690px] text-justify ${
                  isLightMode ? "text-text-light" : "text-text-dark"
                }`}
                style={{
                  margin: "0",
                }}
              >
                with a strong technical background and passion for creating
                seamless, human-centered digital experiences. I’m currently
                based in the cold but beautiful Finland.
              </p>
            </div>
          </div>

          {/* Right Section */}
          <div className="min-w-[169px] max-w-[373px] inline-flex  items-center flex-1 grow self-stretch relative">
            {/* Left Vertical Line */}
            <div className={`absolute left-0 w-0.5 h-screen ${
                mode === "dark" ? "bg-[#eeeeee]" : "bg-[#333333]"
              }`}
            ></div>

        
            {/* Content */}
            <div className="flex-col gap-[13px] items-center flex-1 grow relative">
              <div className="flex-col flex mt-[30px] justify-center items-center gap-2.5 relative self-stretch w-full">
                <img
                  className={`relative flex-1 h-[372px] self-stretch w-full object-cover border ${
                    mode === "dark" ? "border-[#eeeeee]" : "border-[#333333]"
                    
                  }`}
                  alt="Me in black and white"
                  src={pictureofme}
                />
              </div>
              <div className={`relative self-stretch font-mono font-normal text-black text-[19px] text-center tracking-[0] leading-[normal] ${
                isLightMode ? "text-text-light" : "text-text-dark"


              }`}>
                Let’s connect and collaborate!
              </div>
            </div>


            {/* Right Vertical Line */}
            <div
              className={`absolute right-0 w-0.5 h-screen ${
                mode === "dark" ? "bg-[#eeeeee]" : "bg-[#333333]"
              }`}
            ></div>
          </div>
        </div>
      );












    } else if (screenSize >= 650) {
      // Medium Screen Layout (Below 1024px but above 650px)
      return (
        <div className="flex items-center pl-0 pr-[30px] py-0 relative">
          
          {/* Left Section */}
          <div className="flex-col mt-[30px] min-w-[480px] items-start justify-center gap-2.5 px-0 py-[3px] flex-1 self-stretch grow flex relative">
            
            {/* Welcome Text */}
            <div className="items-center gap-2.5 pl-[15px] pr-[77px] py-0 self-stretch w-full flex-[0_0_auto] flex relative">
              <p className={`relative flex-1 max-w-[400px] mt-[-1.00px] leading-super-tight font-mono font-normal text-black text-[15px] text-justify tracking-[0] ${
                isLightMode ? "text-text-light" : "text-text-dark"
              }`}>
                Welcome to my digital space! Here, you’ll find my portfolio
                showcasing design projects and some analog photography. I’m a
              </p>
            </div>
    
            {/* UI Engineer Image */}
            <img
              className="w-full h-auto object-contain" // Height adjusts based on the image width
              alt={`UI Engineer in ${mode} mode`}
              src={imageSrc}
            />
    
            {/* About Text */}
            <div className="flex items-center justify-end gap-2.5 pl-[77px] pr-[15px] py-0 relative self-stretch w-full flex-[0_0_auto]">
              <p className={`relative flex-1 mt-[-1.00px] max-w-[400px] font-mono leading-super-tight font-normal text-black text-[15px] text-justify tracking-[0] ${
                isLightMode ? "text-text-light" : "text-text-dark"
              }`}>
                with a strong technical background and passion for creating
                seamless, human-centered digital experiences. I’m currently based in
                the cold but beautiful Finland.
              </p>
            </div>
          </div>
    
          {/* Right Section */}
          <div className="min-w-[147px] max-w-[266px] inline-flex items-center flex-1 grow self-stretch relative">

            {/* Left Vertical Line */}
            <div
              className={`absolute left-0 w-0.5 h-screen ${
                mode === "dark" ? "bg-[#eeeeee]" : "bg-[#333333]"
              }`}
            ></div>
    

        



            {/* Image and Text Section */}
            <div className="flex-col h-[260px] justify-center items-center flex-1 grow flex relative">
              <div className="flex-col gap-2.5 mt-[30px] items-center self-stretch w-full flex relative">
                
                {/* Picture of Me */}
                <img
                  className={`relative self-stretch w-full object-cover border ${
                    mode === "dark" ? "border-[#eeeeee]" : "border-[#333333]"
                  }`}
                  alt="Me without color"
                  src={pictureofme}
                />
    
    </div>
    
                {/* Collaboration Text */}
                <div className={`relative self-stretch font-mono font-normal text-black text-[13px] text-center tracking-[0] leading-[normal] ${
                isLightMode ? "text-text-light" : "text-text-dark"
              }`}>
                Let’s connect and collaborate!
                </div>
              </div>
       
    







    
            {/* Right Vertical Line */}
            <div
              className={`absolute right-0 w-0.5 h-screen ${
                mode === "dark" ? "bg-[#eeeeee]" : "bg-[#333333]"
              }`}
            ></div>
          </div>
        </div>
      );
  
    







    } else {
      // Small Screen Layout (Below 650px)
      return (










  <div className="flex flex-col justify-start items-center p-[20px] gap-5 min-w-[98px] px-[20px] pt-[20px] pb-[50px]">

      
      {/* Image Section */}
      <div className="flex flex-row justify-center items-center gap-[4px]">
      <div className="flex flex-col max-w-[280px] justify-center items-center gap-[13px]">
      <img
          className="relative flex-1 self-stretch w-full grow object-cover"
          alt="Me in bw"
          src={pictureofme}
        />
      </div>
      </div>
  



      {/* Content Section */}
      <div className="flex-col items-start gap-2.5 w-full flex-[0_0_auto] flex relative self-stretch">
        {/* Welcome Text */}
        <div className="items-center gap-2.5 pl-0 pr-[77px] py-0 w-full flex-[0_0_auto] flex relative self-stretch">
          <p className={`relative flex-1 self-stretch mt-[-1.00px] font-mono font-normal text-justify tracking-[0] leading-[normal] text-[12px] lg:text-[15px] lg:leading-[23.7px] ${
                isLightMode ? "text-text-light" : "text-text-dark"
              }`}>
            
            Welcome to my digital space! Here, you’ll find my portfolio
            showcasing design projects and some analog photography. I’m a
          </p>
        </div>

        {/* "UI Engineer" Image */}
        <img
          className="relative self-stretch w-full h-[57px] object-contain"
          alt={`UI Engineer we are in ${mode} mode`}
          src={imageSrc}
        />

        {/* About Section */}
        <div className="items-center justify-end gap-2.5 pl-[77px] pr-px py-0 w-full flex-[0_0_auto] flex relative self-stretch">
          <p className={`relative flex-1 self-stretch mt-[-1.00px] font-mono font-normal text-[12px] text-justify tracking-[0] leading-[normal] ${
                isLightMode ? "text-text-light" : "text-text-dark"
              }`}>
            with a strong technical background and passion for creating
            seamless, human-centered digital experiences. I’m currently based
            in the cold but beautiful Finland.
          </p>
        </div>
      </div>
    </div>
      );
    }
  };

  return (
    <div className="w-full h-screen flex items-center justify-center overflow-hidden">
      {/* Background Gradient */}
      <BackgroundGradient className="absolute left-0 w-full z-0 pointer-events-none overflow-hidden" />

      {/* Render the layout based on screen size */}
      {getLayout()}
    </div>
  );
};

export default HomePage;
